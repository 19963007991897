$primary: #1e1e96;
$border-color: #e0e0e0;

::-webkit-scrollbar {
  width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.main {
  background-color: white;
  border: 1px solid rgba(224, 224, 224, 1);
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0px 1px 3px rgba(95, 116, 141, 0.08),
    0px 1px 1px rgba(95, 116, 141, 0.04),
    0px 2px 1px -1px rgba(95, 116, 141, 0.03);
  padding: 20px 20px;
  overflow: hidden;
}

a {
  color: $primary;
  text-decoration: none;
  font-size: unset !important;
}

.tableTools {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $border-color;
  padding-bottom: 10px;
  min-height: 50px;
}

input {
  padding: 5px;
}
.input-al {
  width: 80px;
  height: 26px;
}

.ellipsis span {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.listItemProduct {
  display: flex;
  align-items: center;
  gap: 5px;
}

.productForm_marginTop {
  margin-top: 20px;
}

.customSelect .MuiPaper-root .MuiMenu-paper .MuiPaper-elevation {
  overflow: hidden !important;
}

.MuiDataGrid-virtualScroller::-webkit-scrollbar {
  height: 10px;
}

.containerCheckbox {
  align-items: center;
  gap: 10px;
  flex-direction: unset !important;
}

.scrollableBox .MuiDataGrid-virtualScroller {
  height: 150px !important;
}

.heightBox .MuiDataGrid-virtualScroller {
  height: 260px !important;
}

.floor {
  position: absolute;
  left: 332px;
  bottom: 28px;
}

.floorRaise {
  position: absolute;
  left: 475px;
  bottom: 28px;
}

.wall {
  position: absolute;
  right: 103px;
  bottom: 159px;
}

.roofHanging {
  position: absolute;
  left: 446px;
  top: 21px;
}

.inputFloor-bottom {
  position: absolute !important;
  left: 17%;
  top: 72%;
  transform: translate(-50%, -50%);
}

.inputFloor-middle {
  position: absolute !important;
  left: 43%;
  top: 68%;
  transform: translate(-50%, -50%);
}

.inputSmall .MuiInputBase-inputSizeSmall {
  padding: 5px 10px !important;
  width: 50px !important;
}

.bgLocation {
  margin: 0px auto;
  background-repeat: no-repeat;
  width: 540px !important;
  height: 400px;
  background-size: 100% auto;
}

.inputFloorRaise-bottom {
  position: absolute !important;
  left: 17%;
  top: 73%;
  transform: translate(-50%, -50%);
}

.inputFloorRaise-middle {
  position: absolute !important;
  left: 43%;
  top: 68%;
  transform: translate(-50%, -50%);
}

.inputFloorRaise-top {
  position: absolute !important;
  right: 13%;
  top: 42%;
  transform: translate(-50%, -50%);
}

.inputWall-bottom {
  position: absolute !important;
  left: 17%;
  top: 73%;
  transform: translate(-50%, -50%);
}

.inputWall-middle {
  position: absolute !important;
  left: 50%;
  top: 68%;
  transform: translate(-50%, -50%);
}

.inputWall-top {
  position: absolute !important;
  right: -2%;
  top: 41%;
  transform: translate(-50%, -50%);
}

.inputRoof-bottom {
  position: absolute !important;
  left: 17%;
  top: 73%;
  transform: translate(-50%, -50%);
}

.inputRoof-middle {
  position: absolute !important;
  left: 40%;
  top: 68%;
  transform: translate(-50%, -50%);
}

.inputRoof-top {
  position: absolute !important;
  right: 25%;
  top: 34%;
  transform: translate(-50%, -50%);
}

.imgHarness {
  width: 150px;
  height: 150px;
  object-fit: contain;
  padding: 10px;
  border: 1px solid rgb(228, 228, 228);
}

.MenuItemSystem .MuiMenuItem-root:hover {
  background-color: none;
}

.MenuItemSystem:hover{
  border: 2px solid #00c8e6;
}

.red {
  color: #ff6262;
}

.MenuItemSystem.MuiButtonBase-root {
  background-color: #fff !important;
}

h3 {
  margin-block-start: 0;
  margin-block-end: 0;
}

.favorite-product {
  position: absolute;
  top: 3px;
  left: 12px;
  height: 27px;
  width: 27px;
  background: url(../../src/assets/images/normal_star.png) no-repeat;
  z-index: 1;
  text-align: center;
  color: #fff;
  font-size: 0;
  transition: all 0.2s;
}

.favorite-product.selected,
.favorite-product:hover{
  background: url(../../src/assets/images/filled_star.png) no-repeat;
}

.bold {
  font-weight: bold;
  max-width: 200px;
  white-space: pre-wrap;
  text-align: center;
  font-size: 12px;
}
.wrap {
  max-width: 150px;
  white-space: pre-wrap;
  text-align: center;
}


.fileName {
  font-weight: bold;
  width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.nav-link.active {
  color: blue;
}
.link {
  color: inherit;
  height: 22px;
}
.ellip {
  max-width: 200px;
  white-space: pre-wrap;
  text-align: center;
  font-size: 12px;
}

.TrackingSteps {
  display: flex;
  justify-content: center;
  gap: 5px;
}

ul {
  list-style: none;
  padding-left: 0;
}

.layout {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 32%;
}

table {
  width: 100%;
  border-collapse: collapse;
}

th,
td {
  padding: 6px 0;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

th {
  background-color: #f5f5f5;
  font-weight: bold;
}

tr:hover {
  background-color: #f9f9f9;
}

.img {
  width: 50px;
  height: 50px;
  object-fit: contain;
}

.ppe {
  display: flex;
  align-items: center;
  gap: 20px;
}

.detail {
  text-align: right;
}

.center {
  align-items: center;
}

.imgApplication {
  width: 70%;
}

.ellipsisBaseComponent {
  max-width: 400px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.floor-bottom {
  position: absolute !important;
  left: 14%;
  top: 73%;
  transform: translate(-50%, -50%);
}

.floor-middle {
  position: absolute !important;
  left: 42%;
  top: 65%;
  transform: translate(-50%, -50%);
}

.floorRaise-bottom {
  position: absolute !important;
  left: 15%;
  top: 72%;
  transform: translate(-50%, -50%);
}

.floorRaise-middle {
  position: absolute !important;
  left: 42%;
  top: 64%;
  transform: translate(-50%, -50%);
}

.floorRaise-top {
  position: absolute !important;
  right: 20%;
  top: 40%;
  transform: translate(-50%, -50%);
}

.wall-bottom {
  position: absolute !important;
  left: 15%;
  top: 73%;
  transform: translate(-50%, -50%);
}

.wall-middle {
  position: absolute !important;
  left: 54%;
  top: 64%;
  transform: translate(-50%, -50%);
}

.wall-top {
  position: absolute !important;
  right: 1%;
  top: 38%;
  transform: translate(-50%, -50%);
}

.roof-bottom {
  position: absolute !important;
  left: 14%;
  top: 73%;
  transform: translate(-50%, -50%);
}

.roof-middle {
  position: absolute !important;
  left: 38%;
  top: 65%;
  transform: translate(-50%, -50%);
}

.roof-top {
  position: absolute !important;
  right: 34%;
  top: 35%;
  transform: translate(-50%, -50%);
}
.img480 {
  width: 480px;
}

.overflow-hidden {
  overflow: hidden;
}

.flex-row {
  flex-direction: row;
}

.w-full {
  width: 100%;
}

.h-full {
  height: 100%;
}

.flex {
  display: flex;
}
.flexReport{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 5px;
}
.flexReportErr{
  background: 'rgb(253, 240, 240)';
   padding: '10px' !important;
   display: 'flex' !important; 
   flex-direction: 'row' !important; 
   gap: '10px' !important; 
   width: 'auto' !important
}
.fixed {
  position: fixed;
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgb(255 255 255 / var(--tw-bg-opacity));
}

.transition {
  transition: 0.4s cubic-bezier(0.19, 1, 0.22, 1);
}

.items-center {
  align-items: center;
}

.flex-col {
  flex-direction: column;
}

.relative {
  position: relative;
}

.text-light-gray-2 {
  --tw-text-opacity: 1;
  color: rgb(128 128 128 / var(--tw-text-opacity));
}

.justify-center {
  justify-content: center;
}

.h-screen {
  height: 100vh;
}

.w-280 {
  width: 280px;
}

.flex-1 {
  flex: 1 1 0%;
}

.px-4 {
  padding-left: 16px;
  padding-right: 16px;
}

.overflow-auto {
  overflow: auto;
}

.px-2 {
  padding-left: 8px;
  padding-right: 8px;
}

.bg-green-400 {
  background-color: #1e1f96;
}

.bg-primary {
  background-color: #1e1f96;
}

.px-6 {
  padding-left: 6px;
  padding-right: 6px;
}

.pt-3 {
  padding-top: 20px;
}

.h-4 {
  height: 16px;
}

.w-4 {
  width: 16px;
}

.mr-2 {
  margin-right: 8px;
}

.mr-4 {
  margin-right: 16px !important;
}

.text-white {
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
}

.w-calc {
  width: calc(100% - 280px);
}

.component-selected:after {
  content: " ";
  pointer-events: none;
  position: absolute;
  left: 0;
  top: 0;
  display: block;
  height: 100%;
  width: 100%;
  border-width: 1px;
  border-style: dashed;
  --tw-border-opacity: 1;
  border-color: rgb(38 128 235 / var(--tw-border-opacity));
}

.pb-1 {
  padding-bottom: 4px;
}

.py-1 {
  padding-top: 4px;
  padding-bottom: 4px;
}

.px-5 {
  padding-left: 20px;
  padding-right: 20px;
}

.justify-end {
  justify-content: flex-end;
}

.cursor-pointer {
  cursor: pointer;
}

.py-2 {
  padding-top: 8px;
  padding-bottom: 8px;
}

.w-50 {
  width: 45%;
}

.t-center {
  text-align: center;
}

.p10 {
  padding: 10px;
}

.border {
  border: 1px solid #cecece;
}

.customs {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin: 10px;
  flex-wrap: wrap;
}

.breakWord {
  word-break: break-word;
  font-size: 12px !important;
}

.canvas {
  border: 1px solid;
}

.konvajs-content {
  canvas {
    border: 1px solid lightgray !important;
  }
}

.layout-main {
  position: relative;
}

.angle-length {
  position: absolute;
  top: 4px;
  left: 15px;
  z-index: 10;
}

.actionImage {
  position: absolute;
  top: 4px;
  right: 15px;
  z-index: 10;
  display: flex;
  gap: 10px;
  align-items: center;
}

.edit {
  background: #f8f9fa;
  border: 1px solid #dee2e6 !important;
  font-size: 30px;
  width: 30px !important;
  height: 30px !important;
  cursor: pointer;
}

.edit.active {
  background-color: #2a2a2a;
  color: white;
}

.checkgr {
  border: 1px solid red;
}

.onerem {
  width: 1rem;
  height: 1rem;
}

.headerTemplate {
  z-index: 3;
  position: relative;
  background: #f4f4f8;
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: -20px;
  padding: 20px 0;
}

.actionImage .active {
  border: 2px solid #1e1e96;
}

.actionImage .unActive {
  background-color: #f8f9fa;
  border: 1px solid #cecece;
}

.hidden {
  display: none !important;
}

.MuiAccordionSummary-root {
  background-color: rgba(0, 0, 0, 0.03) !important;
  .MuiAccordionSummary-content {
    flex-wrap: wrap;
  }
}
.close-icon {
  font-size: 16px !important;
  color: rgba(0, 0, 0, 0.54);
}

.loading {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

.floor-diagram {
  position: relative;

  .floor-hhl {
    position: absolute;
    right: 250px;
    top: 45px;
  }

  .floor-fall {
    position: absolute;
    left: 300px;
    top: 45px;
  }

  .floor-srl {
    position: absolute;
    top: 85px;
    left: 70px;
  }

  .floor-harness {
    position: absolute;
    left: 0px;
    top: 140px;
  }

  .floor-platform {
    position: absolute;
    left: 285px;
    top: 250px;
  }

  .floor-margin {
    position: absolute;
    bottom: 80px;
    left: 100px;
  }

  .floor-remaining {
    position: absolute;
    bottom: 25px;
    left: 57px;
  }

  .floor-metrics {
    position: absolute;
    top: 160px;
    left: 400px;
  }
}

.raise-diagram {
  position: relative;

  .raise-hhl {
    position: absolute;
    right: 370px;
    top: 55px;
  }

  .raise-fall {
    position: absolute;
    left: 240px;
    top: 80px;
  }

  .raise-srl {
    position: absolute;
    top: 135px;
    left: 55px;
  }

  .raise-harness {
    position: absolute;
    left: 0;
    top: 190px;
  }

  .raise-platform {
    position: absolute;
    left: 285px;
    top: 290px;
  }

  .raise-margin {
    position: absolute;
    bottom: 80px;
    left: 100px;
  }

  .raise-remaining {
    position: absolute;
    bottom: 25px;
    left: 57px;
  }

  .raise-metrics {
    position: absolute;
    top: 200px;
    left: 375px;
  }

  .raise-raise {
    position: absolute;
    right: 260px;
    top: 130px;
  }
}

.wall-diagram {
  position: relative;

  .wall-hhl {
    position: absolute;
    right: 200px;
    top: 160px;
  }

  .wall-fall {
    position: absolute;
    left: 340px;
    top: 215px;
  }

  .wall-srl {
    position: absolute;
    top: 300px;
    left: 75px;
  }

  .wall-harness {
    position: absolute;
    left: 80px;
    top: 360px;
  }

  .wall-platform {
    position: absolute;
    left: 310px;
    bottom: 180px;
  }

  .wall-margin {
    position: absolute;
    bottom: 70px;
    left: 115px;
  }

  .wall-remaining {
    position: absolute;
    bottom: 15px;
    left: 70px;
  }

  .wall-metrics {
    position: absolute;
    left: 480px;
    bottom: 290px;
  }

  .wall-raise {
    position: absolute;
    right: 115px;
    top: 280px;
  }
}

.pb-150 {
  padding-bottom: 150px;
}
.pb20 {
  padding-bottom: 20px;
}
.roof-diagram {
  position: relative;

  .roof-hhl {
    position: absolute;
    right: 395px;
    top: 105px;
  }

  .roof-fall {
    position: absolute;
    left: 235px;
    top: 185px;
  }

  .roof-srl {
    position: absolute;
    top: 310px;
    left: 85px;
  }

  .roof-harness {
    position: absolute;
    left: 90px;
    top: 375px;
  }

  .roof-platform {
    position: absolute;
    left: 310px;
    bottom: 180px;
  }

  .roof-margin {
    position: absolute;
    bottom: 80px;
    left: 115px;
  }

  .roof-remaining {
    position: absolute;
    bottom: 25px;
    left: 70px;
  }

  .roof-metrics {
    position: absolute;
    left: 395px;
    bottom: 290px;
  }

  .roof-raise {
    position: absolute;
    right: 300px;
    top: 230px;
  }
}
.component-canvas-backdrop{
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9;
}
.component-canvas {
  background-color: white;
  border: 1px solid #dee2e6;
  min-width: 500px;
  max-width: 500px;
  padding: 8px;
  z-index: 10;
}

.component-image {
  width: 100px;
}

.opaciti065 {
  opacity: 0.65;
}
.optional {
  background-color: #1e1e96;
  color: white;
  padding: 8px 16px;
}
.option {
  cursor: pointer;
  padding: 8px 16px;
  border: 1px solid;
}
.button {
  padding: 8px 16px;
  background-color: #1e1e96;
  text-transform: uppercase;
  cursor: pointer;
  color: white;
  font-weight: 600 !important;
  height: 38.75px;
  display: flex;
  align-items: center;
}

.system {
  display: flex;
  margin-top: 0px !important;
  width: 100%;
  .system-header {
    background-color: rgba(0, 0, 0, 0.03);
    padding: 12px 12px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .system-option {
    display: flex;
    padding: 12px 12px 0 12px;
    background-color: #ffffff;
    align-items: center;
    justify-content: space-between;
  }

  .system-bottom {
    background-color: #ffffff;
    padding: 12px;
  }

  .system-accordion {
    width: 25%;
  }
  .system-layout {
    flex: 1;
  }
}
.components {
  display: flex;
  max-height: 80px;
  padding-bottom: 10px;
  border-bottom: 1px solid #dee2e6;
  margin-bottom: 10px;
  gap: 10px;
}
.productItem :hover {
  border: 2px solid #00c8e6;
}
.p24 {
  padding: 24px 0 10px;
}
.footer {
  padding: 8px 20px;
  background-color: #d2d2d2;
  width: 100%;
  position: relative;
  z-index: 1200;
  p {
    font-size: 12px;
  };
  flex-shrink: 0;
  margin-top: auto
}
.error {
  color: #e84d48 !important;
}
.error-input {
  .MuiFormLabel-root {
    color: #e84d48 !important;
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: #e84d48 !important;
  }
}
table {
  width: 100%;
}
table,
table td {
  border-top-width: 1px;
  border-right-width: 1px;
  border-bottom-width: 1px;
  border-left-width: 1px;
  border-top-style: solid;
  border-right-style: solid;
  border-bottom-style: solid;
  border-left-style: solid;
  border-top-color: rgb(204, 204, 204);
  border-right-color: rgb(204, 204, 204);
  border-bottom-color: rgb(204, 204, 204);
  border-left-color: rgb(204, 204, 204);
}
.hidden,
[hidden] {
  display: none;
}
table {
  border-collapse: collapse;
}
table td {
  padding-top: 8px;
  padding-right: 5px;
  padding-bottom: 8px;
  padding-left: 5px;
}
.py-3 {
  padding-top: 3px;
  padding-bottom: 3px;
}
.py-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.px-15 {
  padding-left: 15px;
  padding-right: 15px;
}
.my-5 {
  margin-top: 5px;
  margin-bottom: 5px;
}
.mb15 {
  margin-bottom: 15px;
}
.mt15 {
  margin-top: 15px;
}
.ml5{
  margin-left: 5px;
}
.flexScreenReport {
  display: flex;
  flex-direction: row;
}
.flexColumn {
  display: flex;
  flex-direction: column;
}
.w100 {
  width: 100%;
}
.w50 {
  width: 50%;
}
p {
  margin: 0;
}
.page {
  width: 960px;
  margin: 40px 0;
}
.imageReport{
  object-fit: cover;
  border: 1px solid #888;
  border-radius: 7px;
}
.pdBtnBaseCpn{
  padding-left: 25px !important;
}
.imgEr{
  width: 20px;
  height: 20px;
  object-fit: contain;
}
@media (max-width: 1440px) {
  .page {
    width: 650px;
    margin: 40px 10px 10px 10px;
  }
  .w-calc {
    width: calc(100% - 287px);
  }

  .roof-top {
    right: 28%;
    top: 35%;
  }

  .roof-bottom {
    left: 12%;
    top: 73%;
  }

  .wall-top {
    right: -5%;
    top: 38%;
  }
  .wall-middle {
    left: 52%;
    top: 65%;
  }
  .wall-bottom {
    left: 13%;
    top: 73%;
  }
  .floorRaise-top {
    right: 13%;
    top: 39%;
  }
  .floorRaise-middle {
    left: 43%;
    top: 65%;
  }
  .floorRaise-bottom {
    left: 13%;
    top: 72%;
  }
  .floor-bottom {
    left: 13%;
    top: 73%;
  }
  .floor-middle {
    left: 43%;
    top: 65%;
  }
  .pdBtnBaseCpn{
    padding-left: 7px !important;
  }
}

@media (max-width: 1366px){
  .roof-top {
    right: 26%;
    top: 35%;
  }
  .wall-top {
    right: -7%;
    top: 38%;
  }
  .floorRaise-top {
    right: 12%;
    top: 39%;
  }
  .pdBtnBaseCpn{
    padding-left: 0px !important;
  }
}

@media (max-width: 1280px){
  .floor-bottom {
    left: 11%;
    top: 73%;
  }
  .floor-middle {
    left: 43%;
    top: 67%;
  }
  .floorRaise-bottom {
    left: 11%;
    top: 72%;
  }
  .floorRaise-middle {
    left: 43%;
    top: 67%;
   }
   .floorRaise-top {
    right: 9%;
    top: 39%;
  }
  .roof-middle {
    left: 38%;
    top: 67%;
  }
  .roof-top {
    right: 24%;
    top: 35%;
   }

   .wall-bottom {
    left: 10%;
    top: 73%;
  }

  .wall-middle {
    left: 52%;
    top: 66%;
  }

  .wall-top {
    right: -9%;
    top: 38%;
  }
}
.infoDocument {
  padding: 20px;
  display: flex;
  flex-direction: column;
  background: #f4f6f8;
  height: calc(100vh - 140px)
}
.flexCenter{
  display: flex;
  align-items: center;
  gap: 20px;
}
.properties{
  display: flex;
    flex-direction: column;
    gap: 12px 
}
.w80px{
  width: 80px;
  word-wrap: break-word
}
.w200px{
  width: 200px;
  word-wrap: break-word
}
.wordWrap{
  word-wrap: break-word
}
.upload{
  display: flex;
  align-items: center;
  width: 250px;
  gap: 10px;
  justify-content: end;
}
.color7575{
  color: #757575;
}
.mb-54{
  margin-bottom: 54px;
}
.hidden, [hidden]{
  display: none;
}
.MuiDataGrid-panelContent{
  padding: 10px 0;
}
.MuiDataGrid-paper{
  padding: 10px
}
.hidden-column {
  display: none;
}
.emoji{
  font-size: 50px;
  text-align: center;
  margin: -20px 0;
}
.text-red {
  color: red;
}
.containerError{
  background: rgb(253, 240, 240);
  padding: 10px;
  gap: 12px;
  display: flex
}
.mr10{
  margin-right: 10px;
}
.text-warning {
  color: rgb(236 154 37);
}
.containerWarning{
  background: rgb(254, 247, 237);
  padding: 10px;
  gap: 12px;
  display: flex
}
.react-pdf__Page__textContent,
.react-pdf__Page__annotations {
  display: none !important;
}
